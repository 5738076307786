<template>
  <BaseDialog
    title="點數顯示名稱設定"
    :btn1-loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="origin">
        <template #label>
          <FormItemTooltipLabel label="點數來源" :tooltip-width="200">
            請選擇第三方點數的來源，請注意，若無選擇，在相關顯示的頁面上將不會呈現點數。
          </FormItemTooltipLabel>
        </template>
        <BaseElSelect v-model="formData.origin" placeholder="請選擇" clearable>
          <BaseElSelectOption
            v-for="item in externalPointSourceConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem prop="name">
        <template #label>
          <FormItemTooltipLabel label="顯示名稱" :tooltip-width="200">
            您可以自行設定點數在客人端顯示的文字，至多 5 個字。例如：紅利點數、Point 等
          </FormItemTooltipLabel>
        </template>
        <BaseElInput v-model="formData.name" :maxlength="5" show-word-limit />
      </BaseElFormItem>
      <BaseElFormItem prop="unit">
        <template #label>
          <FormItemTooltipLabel label="顯示單位" :tooltip-width="200">
            您可以自行設定點數在客人端顯示的文字，至多 3個字。例如：點、dot 等
          </FormItemTooltipLabel>
        </template>
        <BaseElInput v-model="formData.unit" :maxlength="3" show-word-limit />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm, mappingSyncFormData } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import notifyMessage from '@/config/notifyMessage'
import { UpdateExternalPointConfig } from '@/api/externalPoint'
import { useShop } from '@/use/shop'
import { externalPointSourceConfig } from '@/config/externalPoint'
import { watchArray } from '@vueuse/core'

export default defineComponent({
  name: 'EditExternalPointDisplaySettingsModal',
  components: { BaseDialog, FormItemTooltipLabel },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { loading, formRef, initFormData, formData, checkForm } = useBaseForm()

    initFormData({
      origin: null,
      name: null,
      unit: null,
    })

    const formRules = computed(() => {
      return {
        // origin: [noEmptyRules()],
        name: [noEmptyRules()],
        unit: [noEmptyRules()],
      }
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }

      const [, err] = await UpdateExternalPointConfig({
        shopId: shopId.value,
        source: formData.origin || null,
        pointName: formData.name,
        unitName: formData.unit,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    onMounted(() => {
      mappingSyncFormData({
        source: 'origin',
        pointName: 'name',
        unitName: 'unit',
      }, props.configData, formData)
    })

    return { formRef, initFormData, formData, formRules, loading, onConfirm, externalPointSourceConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
